import React, { useState } from "react";
import app from "gatsby-plugin-firebase-v9.0";
import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";
import { StaticImage } from "gatsby-plugin-image";
import { firebaseErrorMessage } from "../utils/firebaseErrorMessage";
import Wolflayout from "../components/wolflayout";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";

const auth = getAuth(app);

const Reinitialiser = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const mode = params.get("mode");
  const code = params.get("oobCode");
  const [newPassword, setNewPassword] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const [error, setError] = useState(null);
  const isBrowser = () => typeof window !== "undefined";

  if (isBrowser() && mode !== "resetPassword") {
    navigate("/404");
  }

  function confirmReset(e) {
    e.preventDefault();
    setResetLoading(true);
    verifyPasswordResetCode(auth, code)
      .then((email) => {
        confirmPasswordReset(auth, code, newPassword)
          .then((resp) => {})
          .catch((error) => {
            setError(error);
          })
          .finally(() => {
            setResetLoading(false);
          });
      })
      .then(() => {
        navigate("/programmation");
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setResetLoading(false);
      });
  }

  return (
    <Wolflayout className="min-h-screen">
      <Helmet>
        <title>
          Réinitialiser votre mot de passe | Le Festif! de Baie-Saint-Paul
        </title>
      </Helmet>
      <div className="max-w-[22rem] rounded-2xl overflow-hidden shadow-lg bg-blue-wolf mt-4 mx-auto">
        <div className="text-center p-8">
          <StaticImage
            className={"z-10 w-9"}
            src={"../images/wolf/mini-logo-festif.png"}
            placeholder="blurred"
            alt="Logo de le festif, version mini"
          />
          <div className="font-poppins text-purple-wolf">
            <h3 className="font-obviously text-white uppercase text-3xl font-normal mt-4 mb-6">
              Réinitialiser le mot de passe
            </h3>
            <form className="m-6">
              <div className="input-name flex flex-col">
                <label
                  htmlFor="password"
                  className="text-left font-semibold mt-3"
                >
                  Nouveau mot de passe
                </label>
                <input
                  onChange={(e) => setNewPassword(e.target.value)}
                  type="password"
                  name="name"
                  id="password"
                  className="rounded-lg h-8 fill-style"
                  required
                />
              </div>
              <div className="flex flex-col text-center mt-8">
                <button
                  onClick={(e) => confirmReset(e)}
                  type="submit"
                  className="bg-pink-wolf text-white text-xl hover:bg-white hover:text-purple-wolf inline-block px-8 rounded-2xl py-4 mx-auto mb-6 uppercase"
                >
                  {resetLoading ? (
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  ) : (
                    "Réinitialiser"
                  )}
                </button>
              </div>
              {error ? (
                <div className="bg-purple-wolf p-4 text-white rounded-xl flex flex-col text-center mt-6">
                  {firebaseErrorMessage(error.code)}
                </div>
              ) : (
                ""
              )}
            </form>
          </div>
        </div>
      </div>
    </Wolflayout>
  );
};

export default Reinitialiser;
